import React from "react"
import MainPage from "../templates/mainPage"
import logo from "../assets/images/logo/Moodbe-Logo.png"
import mocks from "../assets/images/phone-left.png"

const Index = props => {

  return (
    <MainPage className="moodbe-home" pageTitle="Moodbe">
      <div className="moodbe-home__wrapper">
        <div className="moodbe-home__logo-container">
            <img className="moodbe-home__logo-container__logo" src={logo} alt=""/>
        </div>
        <h1 className="moodbe-home__headline">You tell us your <b>mood</b>, we&apos;ll tell you where to <b>be</b>.</h1>
        <a href="#" className="moodbe-home__download-button">Coming Soon to iOS</a>
      </div>
      <div className="moodbe-home__background-wrapper">
        <div className="moodbe-home__background-wrapper__image" style={{backgroundImage: `url(${mocks})`}}></div>
      </div>
    </MainPage>
  )
}

export default Index
